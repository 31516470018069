.login-background {
  position: absolute;
  top: 0;
  height: 50vh;
  width: 100vw;
  background-color: #46c494;
  background-image: url("../../assets/backgroundVetorDex.gif");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
  z-index: 0;
}

.LogoVetorDex {
  height: 10vmin;
}

.MuiIconButton-root {
  background-color: white !important;
}

.login-div-logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  color: white;
}
.login-container {
  background-color: #004d64;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: white;  
}

.login-form-container {
  z-index: 1;
  display: flex;
  align-self: center;
  justify-content: center;
  flex-direction: column;
  margin: 0px auto;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.3s ease 0s;
  background-color: #004d64;
  border-radius: 8px;
  padding: 40px 80px;
}

@media only screen and (max-width: 413px) {
  .login-form-container{
    width: 70vw;
    padding: 20px 40px;
  }
}

.login-form-container input {
  display: flex;
  background-color: #fff;
  border-radius: 8px;
  justify-content: center;;
}

.meuCollapse {
  margin-top: 5%;
  z-index: 80;
}

.footer {
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.login-ButtonForm {
  width: 140px;
  height: 45px;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #004d64;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  margin-top: 40px;
}

.login-ButtonForm:hover {
  background-color: #2ee59d;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #000;
  transform: translateY(-7px);
}

.login-divButton {
  display: flex;
  align-items: center;
}
