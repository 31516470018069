#contato {
  text-align: center;
  width: 100vw;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.App-header h1 {
  /* z-index: 1; */
  margin: 0 0 30px 0;
  color: white;
}

.App-header {
  background-color: #034C64;
  /* background-color: white; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: white;
}

.footer {
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.background {
  position: absolute;
  top: 0;
  height: 50vh;
  width: 100vw;
  background-color: #46c494;
  background-image: url("../../assets/backgroundVetorDex.gif");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
  z-index: 0;
}

.btn-menu {
  background-color: white;
  padding: 20px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  color: #004d64;
  font-weight: bold;
}

.btn-menu:hover {
  background-color: #2ee59d;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #000;
  /* transform: translateY(-7px); */
}

.App-header form {
  margin-top: 60px;
  z-index: 1;
  width: 40vw;
  /* background-color: #0002; */
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.3s ease 0s;
  padding: 40px 80px;
  background-color: #034C64;
}

@media only screen and (max-width: 1000px) {
  .App-header form{
    width: 80vw;
    padding: 20px 20px;
  }
}

.App-header form text {
  font-size: large;
  margin-bottom: 40px;
  text-align: start;
}

.form-group {
  display: flex;
  flex-direction: row;
  margin: 8px 0;
}

.form-group > label {
  display: flex;
  align-self: flex-start;
  align-self: center;
  /* background-color: #0002; */
  /* width: 30%; */
  min-width: 100px;
  font-size: medium;
}

.form-group > input {
  height: 30px;
  width: 50%;
  border-width: 0;
  border-radius: 4px;
}

.form-group > textarea {
  border-width: 0;
  border-radius: 4px;
  width: 100%;
}

.App-header form button {
  align-self: center;
}

.form-btn {
  width: 140px;
  height: 45px;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 800;
  color: #034C64;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  margin-top: 40px;
}

.form-btn:hover {
  background-color: #2ee59d;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #000;
  transform: translateY(-4px);
}

#text {
  font-size: 14pt;
  text-align: left;
  margin-bottom: 20px;
}
