.PoliticaDePrivacidade {
  background-color: black;
}

.main-div {
  z-index: 1;
  width: 40vw;
  /* background-color: #0002; */
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.3s ease 0s;
  padding: 40px 80px;
  background-color: #034C64;
  margin: 80px 0;
}

.main-div p, .main-div span, .main-div li {
  font-size: 14pt;
}