html {
  width: 100vw;
  overflow-x: hidden;
}

.Arquivos {
  text-align: center;
}

.arquivos-App-logo {
  height: 10vmin;
  pointer-events: none;
}

.form-header {
  background-color: #002d44;
  border-radius: 8px 8px 0 0;
}

.form-footer {
  background-color: #002d44;
  border-radius: 0 0 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.arquivos-App-header h1 {
  /* z-index: 1; */
  /* margin: 0 0 40px 0; */
  font-size: 26pt;
  color: white;
  /* font-size: 30pt */
}

.arquivos-App-header {
  background-color: #034C64;
  /* background-color: white; */
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: white;
}

.arquivos-footer {
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.arquivos-background {
  position: absolute;
  top: 0;
  height: 50vh;
  width: 100vw;
  background-color: #46c494;
  background-image: url("../../assets/backgroundVetorDex.gif");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
  z-index: 0;
}

.btn-menu {
  background-color: white;
  padding: 20px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  color: #034C64;
  font-weight: bold;
  border-style: solid;
  border-width: 0 2px 2px 2px;
  border-color: #033C54;
  font-size: large;
}

.btn-menu:hover {
  background-color: #2ee59d;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #000;
  /* transform: translateY(-7px); */
}

.arquivos-App-header form {
  z-index: 1;
  width: 40vw;
  /* background-color: #0002; */
  /* height: 600px; */
  /* overflow-y: auto; */
  /* scrollbar-gutter: stable both-edges; */
  
  /* scroll-behavior: smooth; */
  border-radius: 8px;
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  flex-wrap: wrap;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.3s ease 0s;
  /* padding: 20px 40px; */
  background-color: #034C64;
  margin-top: 60px;
}

@media only screen and (max-width: 700px) {
  .form-main{
    padding: 20px 20px;
  }
  .arquivos-App-header form {
    width: 80vw;
  }
}

.arquivos-App-header form text, .arquivos-App-header form span {
  font-size: 12pt;
  text-align: start;
  margin-bottom: 20px;
}

.arquivos-App-header form p {
  text-align: start;
  font-size: 10pt;
  line-height: 0;
}


#input-div {
  display: flex;
  align-items: baseline;
}

.arquivos-App-header form input[type="file"] {
  display: none;
}

.arquivos-App-header form button {
  align-self: center;
}

.form-main {
  display: flex;
  /* width: 100%; */
  flex-direction: column;
  padding: 20px 40px;
  background-color: #034C64;
  /* margin: 20px 0; */
  /* justify-content: center; */
  /* justify-content: space-between; */
  min-height: 200px;
}

#input-xls-div, #input-images-div {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  /* background-color: #2ee59d; */
}

#file-name {
  font-size: 14pt;
  color: #2ee59d;
  margin: 10px 0;
}

.arquivos-App-header form label {
  font-size: large;
  /* align-self: center; */
  /* margin-right: 20px; */
  border-style: solid;
  border-width: 2px;
  border-color: #033C54;
  padding: 8px 10px;
  width: 220px;
  background-color: azure;
  color: #034C64;
  border-radius: 8px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-size: 12pt;
  font-weight: 700;
}

.arquivos-App-header form label:hover {
  background-color: #2ee59d;
  transition: all 0.3s;
}

#labelinputImageFile[disabled] {
  background-color: #888;
  border-style: none;
  color: black;
  cursor: not-allowed;
}

.hidden-div {
  opacity: 0;
  height: 0;
  display: flex;
  flex-direction: column;
  transform:translateY(-100px);
  transition: all 0.3s ease 0s;
}

.images-to-upload{
  /* height: 80vh; */
  /* width: 50vw; */
  /* top: 10%; */
  /* left: 30%; */
  text-align: start;
  padding: 20px;
  margin: 0 0 4px 0;
  max-height: 200px;
  overflow-y: auto;
  scroll-behavior: smooth;
  /* position: absolute; */
  border-radius: 8px;
  background-color: #003d54;
  transition: all 0.3s ease 0s;
}

.legendas {
  display: block;
  text-align: start;
  font-size: 12pt;
}

.legendas > span {
  font-size: 10pt !important;
  /* line-height: 12px; */
}

.legendas span span {
  font-size: 16pt;
  line-height: 10pt;
}

.images-to-upload li {
  list-style-type: none;
  font-size: 12pt;
}

.Arquivos main form button {
  width: 140px;
  height: 45px;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 800;
  color: #034C64;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  /* margin-top: 40px; */
}

.Arquivos main form button[disabled] {
  width: 140px;
  height: 45px;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 800;
  color: black;
  background-color: #888;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  cursor: not-allowed;
  /* margin-top: 40px; */
}

.Arquivos main form button:hover {
  background-color: #2ee59d;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #000;
  transform: translateY(-4px);
}

.Arquivos main form button[disabled]:hover {
  background-color: #888;
  box-shadow: 0px 0 0 rgba(46, 229, 157, 0.4);
  color: #034C64;
  transform: translateY(0px);
}

/* width */
::-webkit-scrollbar {
  border-radius: 8px;
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
  /* box-shadow: inset 0 0 5px grey;  */
  background-color: #002d44;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2ee59d;
}